<template>
  <div class="search-content">
    <form class="search-box" action="/">
      <Search
        v-model="text"
        show-action
        autofocus
        placeholder="请输入搜索关键词"
        left-icon=""
        @input="handelSearch"
        @cancel="handelRouteBack"
      />
    </form>

    <!-- 结果列表 -->
    <ul v-if="resultList.length" class="list">
      <Scard v-for="item in resultList" :key="item.id" :cardData="item"> </Scard>
    </ul>

    <!-- 缺省页 -->
    <Empty v-else imgWidth="0.32rem" imgHeight="0.32rem" :image="noInfo" description="暂无信息，请重新输入" />
  </div>
</template>

<script>
import { Search, Icon } from 'vant';
import { defineComponent, ref, onMounted, onBeforeUnmount } from '@vue/composition-api';
import Empty from '@components/Empty/index.vue';
import { useRouter } from '@/utils/compsitionHack';
import { useRouterQuery } from '@hook/useRouterQuery';
import useDebounce from '@hook/useDebounce';
import UA from '@/client/ua';
import ClientApp from '@/client/index';
import Scard from '../components/card';
import { apiGetSourceList } from '@/api/source';

export default defineComponent({
  name: 'Source',
  components: { Search, Empty, Icon, Scard },
  setup() {
    const router = useRouter();
    const query = useRouterQuery();

    // 改变页面背景颜色
    onMounted(() => {
      document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5');
    });

    // 缺省页图片
    const noInfo = require('@/assets/icon_noInfo.png');
    // 搜索内容
    const text = ref('');
    // 搜索结果列表
    const resultList = ref([]);

    const handelSearch = useDebounce(() => {
      if (!text.value) {
        resultList.value = [];
        return;
      }
      apiGetSourceList({
        param: text.value,
        userLat: query.userLat || 120.2,
        userLng: query.userLng || 30.3
      }).then(res => {
        resultList.value = res.data;
      });
    }, 500);

    // 返回上一页
    const handelRouteBack = () => {
      // 如果是在客户端环境，调用客户端返回方法
      if (UA.androidClient) {
        ClientApp.finish();
      } else {
        router.back();
      }
    };

    // 卸载组件前
    onBeforeUnmount(() => {
      document.querySelector('body').removeAttribute('style');
    });

    return {
      noInfo,
      text,
      resultList,
      handelSearch,
      handelRouteBack,
      useDebounce
    };
  }
});
</script>
<style lang="less" scoped>
.search-content {
  overflow: hidden;
  .search-box {
    position: fixed;
    z-index: 99999;
    width: 100%;
  }
  .list {
    padding: 0.12rem;
    margin-top: 0.44rem;
    li {
      border-bottom: 0.01rem solid #ebebeb;
      height: 0.48rem;
      line-height: 0.52rem;
      color: #666;
      font-size: 0.16rem;
      padding-right: 0.12rem;
      background: url('../../../../assets/icon_back.png') right center no-repeat;
    }
  }
}
</style>
<style lang="less">
.highlights-text {
  color: #1679ff;
}
.van-search__content {
  border: 0.01rem solid #bcbcbc;
  height: 0.3rem;
  border-radius: 0.06rem;
}
.van-search {
  padding: 0.07rem 0 0.07rem 0.12rem;
  .van-cell {
    padding: 0.05rem 0;
  }
  .van-field__body {
    height: 100%;
  }
  .van-field__control {
    height: 100%;
    width: 90%;
    font-weight: 600;
  }
  .van-icon-search {
    font-size: 0.2rem;
    color: #999;
  }
  .van-icon-clear {
    font-size: 0.15rem;
    color: #1679ff;
    padding-right: 0.16rem;
  }
  .van-search__action {
    color: #1677ff;
    font-size: 0.16rem;
    padding: 0 0.12rem;
    font-weight: 600;
  }
}
</style>
